<template>
  <div class="landlord-user-detail">
    <app-detail :opt="opt" ref="detail"></app-detail>
    <el-dialog
      title="子账号列表"
      :visible.sync="dialogVisible"
      width="50%"
      >
        <el-table
          :data="subAccountList"
          style="width: 100%;height: 600px;overflow-y: auto;">
        <el-table-column
          prop="realname"
          label="姓名"
          align="left"
          >
        </el-table-column>
        <el-table-column
          prop="username"
          label="登录账号"
          align="left"
          >
        </el-table-column>
        <el-table-column
          prop="roleName"
          label="角色"
          align="left">
        </el-table-column>
        <el-table-column
          prop="createdDate"
          label="添加时间"
          align="left">
        </el-table-column>
      </el-table>
      <el-pagination
        background
        style="margin-top: 20px;margin-left:6px;"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="subAccountTotal"
        >
      </el-pagination>
    </el-dialog>
  </div>
</template>
<script>
  let validID = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      let reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (!reg.test(value)) {
        callback(new Error("身份证号码不正确"));
      } else {
        callback();
      }
    }
  };

  let validMobile = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      let reg = /^1[3456789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("电话号码格式不对"));
      } else {
        callback();
      }
    }
  };
  export default {
    data() {
      let _this = this;
      return {
        currentPage: 1,
        pageSize: 10,
        dialogVisible: false,
        subAccountList: [],
        subAccountTotal: 0,
        id: "",
        AllList: [],
        opt: {
          title: "房东信息",
          form: [
            {
              label: "商户信息",
              type: "TitleName",
            },
            {
              label: "商户编号",
              key: "orgCode",
              isCol50: true,
              disabled: true,
            },
            {
              label: "商户名称",
              key: "orgName",
              isCol50: true,
              disabled: true,
            },
            {
              label: "商户类型",
              key: "orgType",
              isCol50: true,
              type: "select",
              opt: {
                list: [{
                    value: 2,
                    label: "个人"
                  },
                  {
                    value: 1,
                    label: "企业/单位"
                  }
                ]
              },
              disabled: true
              // rules: [{ required: true }]
            },
            {
              label: "房源（个）",
              key: "houseCount",
              disabled: true,
              isCol50: true,
            },
            {
              label: "企业/单位名称",
              key: "entName",
              disabled: true,
              isCol50: true,
              filters: {
                key: "orgType",
                value: 1
              },
              rules: [{
                required: true
              }]
            },
            {
              label: "统一社会信用代码",
              key: "socialCreditCode",
              disabled: true,
              isCol50: true,
              filters: {
                key: "orgType",
                value: 1
              },
              rules: [{
                required: true
              }]
            },
            {
              label: "是否法人认证",
              key: "isAuthorizer",
              type: "select",
              isCol50: true,
              filters: {
                key: "orgType",
                value: 1
              },
              opt: {
                list: [{
                    value: 0,
                    label: "是"
                  },
                  {
                    value: 1,
                    label: "否"
                  }
                ]
              },
              disabled: true
            },
            {
              label: "管理员信息",
              type: "TitleName",
            },
            {
              label: "管理员姓名",
              key: "realName",
              disabled: true,
              isCol50: true,
              rules: [{
                required: true
              }]
            },
            {
              label: "性别",
              key: "gender",
              type: "select",
              opt: {
                list: [{
                    value: "1",
                    label: "男"
                  },
                  {
                    value: "2",
                    label: "女"
                  }
                ]
              },
              isCol50: true,
              disabled: true
              // rules: [{ required: true }]
            },
            {
              label: "出生日期",
              key: "birthday",
              disabled: true,
              isCol50: true
              // rules: [{ required: true }]
            },
            {
              label: "身份证号",
              key: "idCardNo",
              disabled: true,
              isCol50: true
            },
            {
              label: "手机号码",
              key: "phoneNum",
              disabled: true,
              isCol50: true,
              rules: [{
                validator: validMobile,
                trigger: "blur"
              }]
            },
            {
              label: "紧急联系人",
              key: "emergencyContract",
              disabled: true,
              isCol50: true,
            },
            {
              label: "紧急联系人电话",
              key: "emergencyPhoneNum",
              disabled: true,
              isCol50: true,
              rules: [{
                required: false,
                validator: validMobile,
                trigger: "blur"
              }]
            },
            {
              label: "推荐人",
              key: "referrerName",
              isCol50: true,
              disabled: true
            },
            {
              label: "推荐人电话",
              key: "referrerPhone",
              isCol50: true,
              disabled: true
            },
            {
              label: "现居城市",
              key: "city",
              disabled: true,
              rules: [{
                required: false
              }],
              isCol50: true,
            },
            {
              label: "详细地址",
              key: "address",
              disabled: true,
              rules: [{
                required: false
              }]
            },
            // {
            //   label: "子账号列表",
            //   type: "TitleName",
            // },
            {
              label: "子账号列表",
              type: "ViewDetails",
              subLabel:"点击查看",
              opt:{
                onCheck() {
                  _this.pageSize = 10;
                  _this.currentPage = 1;
                  _this.subAccountList = [];
                  _this.subAccountTotal = 0;

                  _this.dialogVisible = true;

                  _this.getSubAccountList();
                }
              }
            },
            // {
            //   label: "    ",
            //   key: "subAccount",
            //   type: "table",
            //   opt: {
            //     isDisabled: true,
            //     columns: [{
            //         label: "姓名",
            //         key: "realname"
            //       },
            //       // { label: "电话号码", key: "phonenum" },
            //       // { label: "邮箱地址", key: "email" },
            //       {
            //         label: "登录账号",
            //         key: "username"
            //       },
            //       {
            //         label: "角色",
            //         key: "roleNames"
            //       },
            //       {
            //         label: "添加时间",
            //         key: "createdDate"
            //       }
            //     ]
            //   }
            // },
            {
              label: "认证审核信息",
              type: "TitleName"
            },
            {
              label: "提交认证时间",
              key: "verifiedDate",
              disabled: true,
              isCol50: true,
            },
            {
              label: "身份审核",
              key: "authState",
              type: "switch",
              opt: {
                activeText: "通过",
                inactiveText: "不通过"
              },
              rules: [{
                required: true
              }]
            },
            {
              label: "学校商户",
              key: "category",
              type: "switch",
              opt: {
                activeText: "是",
                inactiveText: "否",
                change(e, cb, form) {
                  _this.categoryChange(e,cb,form)
                  if(e) {
                    cb({
                      key: "isPlan",
                      type: 'adRangeType',
                      defaultValue: true,
                      list: [],
                    });
                  }
                }
              },
              filters: {
                key: "authState",
                value: "1"
              },
            },
            {
              label: "房东开启已出租的房客门",
              key: "isOpen",
              type: "switch",
              opt: {
                activeText: "允许",
                inactiveText: "不允许"
              },
              filters: {
                key: "authState",
                value: "1"
              },
              rules: [{
                required: true
              }]
            },
            {
              label: "房东线下收款，给房客办理排房",
              key: "isPlan",
              type: "switch",
              opt: {
                activeText: "允许",
                inactiveText: "不允许",
                change(e, cb, form) {
                  if (e && !_this.isPlan) {
                    cb({
                      key: "isPlanAutoRenew",
                      type: 'adRangeType',
                      defaultValue: true,
                      list: []
                    });
                  }
                }
              },
              filters: {
                key: "authState",
                value: "1"
              },
              rules: [{
                required: true
              }]
            },
            {
              label: "排房到期自动续期",
              key: "isPlanAutoRenew",
              type: "switch",
              defaultValue: true,
              rules: [{
                required: true
              }],
              opt: {
                activeText: "打开",
                inactiveText: "关闭"
              },
              filters: [{
                  key: "isPlan",
                  value: "1"
                },
                {
                  key: "authState",
                  value: "1"
                }
              ]
            },
            {
              label: "允许未绑锁的房间排房",
              key: "isPlanUnbind",
              type: "switch",
              defaultValue: true,
              rules: [{
                required: true
              }],
              opt: {
                activeText: "允许",
                inactiveText: "不允许"
              },
              filters: [{
                  key: "isPlan",
                  value: "1"
                },
                {
                  key: "authState",
                  value: "1"
                }
              ]
            },
            {
              label: "选择退房模式",
              key: "outState",
              type: "radioHasText",
              defaultValue: "1",
              opt: {
                list: [{
                  label: "模式1：房客退房，房客和同住人一同退房",
                  id: "1",
                  disabled: false,
                  text: "说明：针对排房的套间，一个房间入住多人，房客退房后，同住人也一同退房了。",
                }, {
                  label: "模式2：房客退房，同住人成为房客",
                  id: "2",
                  disabled: false,
                  text: "说明：针对排房的套间，一个房间入住多人，房客退房后，仅房客自己退房，同住人会自动成为房客并且在住。",
                }]
              },
              filters: [{
                  key: "isPlan",
                  value: "1"
                },
                {
                  key: "authState",
                  value: "1"
                }
              ]
            },
            {
              label: "授权房源",
              key: "apartments",
              type: "select-filterables",
              filters: [{
                  key: "isPlan",
                  value: "1"
                },
                {
                  key: "authState",
                  value: "1"
                },
              ],
              rules: [{
                required: true
              }],
              opt: {
                list: [],
                change(arr, cb, form) {
                  // console.log(arr);
                  if (form && form.hasOwnProperty("apartments")) {
                    if (arr.length > 0 && arr[arr.length - 1] == 0) {
                      form["apartments"] = ["0"];
                    } else if (arr.length > 1 && arr[0] == 0) {
                      arr.shift();
                      form["apartments"] = arr;
                    }
                  } else {
                    console.warn("属性不存在：fapartmentIds");
                  }
                }
              }
            },
            {
              label: "要求租客上传身份证照片",
              key: "isUploadPic",
              type: "switch",
              opt: {
                activeText: "开启",
                inactiveText: "关闭"
              },
              filters: {
                key: "authState",
                value: "1"
              },
              rules: [{
                required: true
              }]
            },
            {
              label: "授权房源",
              key: "uploadPicApartments",
              type: "select-filterables",
              filters: [{
                  key: "isUploadPic",
                  value: "1"
                },
                {
                  key: "authState",
                  value: "1"
                },
              ],
              rules: [{
                required: true
              }],
              opt: {
                list: [],
                change(arr, cb, form) {
                  // console.log(arr);
                  if (form && form.hasOwnProperty("uploadPicApartments")) {
                    if (arr.length > 0 && arr[arr.length - 1] == 0) {
                      form["uploadPicApartments"] = ["0"];
                    } else if (arr.length > 1 && arr[0] == 0) {
                      arr.shift();
                      form["uploadPicApartments"] = arr;
                    }
                  } else {
                    console.warn("属性不存在：uploadPicApartments");
                  }
                }
              }
            },
            {
              label: "实名发蓝牙钥匙",
              key: "isKey",
              type: "switch",
              opt: {
                activeText: "开启",
                inactiveText: "关闭"
              },
              filters: {
                key: "authState",
                value: "1"
              },
              rules: [{
                required: true
              }]
            },
            {
              label: "房东审核添加/删除同住人",
              key: "isCohabit",
              type: "switch",
              opt: {
                activeText: "开启",
                inactiveText: "关闭"
              },
              filters: {
                key: "authState",
                value: "1"
              },
              rules: [{
                required: true
              }]
            },
            {
              label: "是否允许房客临时授权开门",
              key: "allowTempAuth",
              type: "switch",
              defaultValue: true,
              opt: {
                activeText: "允许",
                inactiveText: "不允许"
              },
              filters: [{
                  key: "isCohabit",
                  value: "1"
                },
                {
                  key: "authState",
                  value: "1"
                },
              ],
            },
            {
              label: "授权房源",
              key: "cohabitApartments",
              type: "select-filterables",
              filters: [{
                  key: "isCohabit",
                  value: "1"
                },
                {
                  key: "authState",
                  value: "1"
                },
              ],
              rules: [{
                required: true
              }],
              opt: {
                list: [],
                change(arr, cb, form) {
                  // console.log(arr);
                  if (form && form.hasOwnProperty("cohabitApartments")) {
                    if (arr.length > 0 && arr[arr.length - 1] == 0) {
                      form["cohabitApartments"] = ["0"];
                    } else if (arr.length > 1 && arr[0] == 0) {
                      arr.shift();
                      form["cohabitApartments"] = arr;
                    }
                  } else {
                    console.warn("属性不存在：cohabitApartments");
                  }
                }
              }
            },
            {
              label: "照片信息",
              key: "idcardimg",
              type: "img-list",
              disabled: true,
              filters: {
                key: "authState",
                value: "1"
              },
            },
          ],
          buttons: [{
            name: "提交",
            show: ["add", "edit"],
            cb(form) {
              let dtoApartmentIds = [];

              if (form.isPlan) {
                if (form.apartments && form.apartments.indexOf("0") != -1) {
                  dtoApartmentIds = [];
                } else {
                  dtoApartmentIds = form.apartments ?
                    JSON.parse(JSON.stringify(form.apartments)) :
                    null;
                }

              } else {
                dtoApartmentIds = ["1"]
              }
              // console.log(dtoApartmentIds);
              // if (dtoApartmentIds.length <= 0 && form.isPlan) {
              //   return _this.$message({
              //     showClose: true,
              //     message: "房源数为0不能授权全部,请配置房源!",
              //     type: "warning"
              //   });
              // }

              let dtoCohabitApartments = [];

              if (form.isCohabit) {
                if (form.cohabitApartments && form.cohabitApartments.indexOf("0") != -1) {
                  dtoCohabitApartments = [];
                } else {
                  dtoCohabitApartments = form.cohabitApartments ?
                    JSON.parse(JSON.stringify(form.cohabitApartments)) :
                    null;
                }

              } else {
                dtoCohabitApartments = ["1"];
              }
              let dtoUploadPicApartments = [];
              if (form.isUploadPic) {
                if (form.uploadPicApartments && form.uploadPicApartments.indexOf("0") != -1) {
                  dtoUploadPicApartments = []
                } else {
                  dtoUploadPicApartments = form.uploadPicApartments ?
                    JSON.parse(JSON.stringify(form.uploadPicApartments)) :
                    null;
                }

              } else {
                dtoUploadPicApartments = ["1"];
              }
              let dto = {
                realName: form.realName,
                birthday: form.birthday,
                idcardAddress: form.idcardAddress,
                idCardNo: form.idCardNo,
                phonenum: form.phoneNum,
                address: form.address,
                emergencyPhoneNum: form.emergencyPhoneNum,
                houseNum: !form.houseCount ? 0 : form.houseCount.toString(),
                isFirst: 1, //标识审核过的房东
              };
              let url = "user-service/apartmentuser/addApartmentuser";
              let message = "添加栏目成功";
              let operateName = '房东审核';
              if (form.userId) {
                url = "user-service/apartmentuser/updateApartmentuser";
                delete dto.houseNum;
                delete dto.createdBy;
                dto.userid = form.userId;
                dto.orgId = _this.$route.params.orgId,
                dto.isWeb = 1;
                (dto.isOpen = form.isOpen ? 1 : 0),
                (dto.isCohabit = form.isCohabit ? 1 : 0),
                (dto.isUploadPic = form.isUploadPic ? 1 : 0),
                (dto.isKey = form.isKey ? 1 : 0),
                (dto.isPlanAutoRenew = form.isPlanAutoRenew ? 1 : 0),
                (dto.isPlanUnbind = form.isPlanUnbind ? 1 : 0),
                (dto.outState = form.outState == "2" ? 2 : 1),
                (dto.isPlan = form.isPlan ? 1 : 0),
                (dto.allowTempAuth = form.allowTempAuth ? 1 : 0),
                (dto.cohabitApartments = dtoCohabitApartments),
                (dto.apartmentIds = dtoApartmentIds),
                (dto.uploadPicApartments = dtoUploadPicApartments);
                message = "编辑栏目成功";
              }
              if (_this.$route.query.isAuto) {
                delete dto.idCardNo;
                delete dto.phonenum;
              }
              _this.post(url, dto, {
                operatorFlage: true,
                operateName: operateName,
              }).then(function () {
                if (form.userId) {
                  _this
                    .post("user-service/user/modifyUserAuthState", {
                      orgId: _this.$route.params.orgId,
                      userId: form.userId,
                      authState: form.authState ? 2 : 1,
                      isAuth: 1,
                      category: form.category ? 7 : 0,//0 普通商户  7  学校类别
                    })
                    .then(() => {
                      _this.$message({
                        showClose: true,
                        message: message,
                        type: "success"
                      });
                      _this.$router.push("/main/landlord/list");
                    });
                  return;
                }
                _this.$message({
                  showClose: true,
                  message: message,
                  type: "success"
                });
                _this.$router.push("/main/landlord/list");
              });
            }
          }],
          get(opt) {
            // if (_this.$route.params.type == 'show') {
            //   _this.opt.form.forEach((item, index) => {
            //     if (item.key == 'idcardimg') {
            //       _this.opt.form.splice(index, 1);
            //     }
            //   })
            // } else {
            //   let flag = true;
            //   _this.opt.form.forEach((item, index) => {
            //     if (item.key == 'idcardimg') {
            //       flag = false;
            //     }
            //   })
            //   if (flag) {
            //     _this.opt.form.splice(_this.opt.form.length, 0, {
            //       label: "照片信息",
            //       key: "idcardimg",
            //       type: "img-list",
            //       disabled: true
            //     })
            //   }
            // }
            let dto = {
              userid: opt.id,
              orgId:  _this.$route.params.orgId,
            };
            _this.id = opt.id;
            _this.post("user-service/apartmentuser/queryApartmentuser", dto)
              .then(data => {
                data.city = (data.province ? data.province : "") + (data.city ? data.city : "") + (data.district ? data.district : "")
                data.usertypeStr = ["主租户", "合租者"][data.usertype - 1];
                // data.userState = data.userState == 2 ? true : false;
                data.genderf = data.genders = data.gender = ["男", "女"][
                  data.gender - 1
                ];
                if (data.apartments === null) //全部
                {
                  data.apartments = ["0"];
                }
                if (data.cohabitApartments === null) //全部
                {
                  data.cohabitApartments = ["0"];
                }
                if (data.uploadPicApartments === null) //全部
                {
                  data.uploadPicApartments = ["0"];
                }
                data.apartments = data.apartments == "" ? "" : data.apartments;
                data.cohabitApartments = data.cohabitApartments == "" ? "" : data.cohabitApartments;
                data.uploadPicApartments = data.uploadPicApartments == "" ? "" : data.uploadPicApartments;
                data.authState = data.authState == 2 ? true : false;
                data.isOpen = data.isOpen == 1 ? true : false;
                _this.isPlan = data.isPlan;
                data.isPlanAutoRenew = data.isPlanAutoRenew == 1 ? true : false;
                data.isPlanUnbind = data.isPlanUnbind == 1 ? true : false;
                data.allowTempAuth = data.allowTempAuth == 1 ? true : false;
                data.outState = data.outState == 2 ? "2" : "1";
                data.isPlan = data.isPlan == 0 ? false : true; //1或2都为true
                data.isCohabit = data.isCohabit == 1 ? true : false;
                data.isKey = data.isKey == 1 ? true : false;
                
                if (data.isFirst == null) //没有审核过的房东，默认‘要求租客上传身份证照片’为否,'房东审核添加同住人'为否
                {
                  data.isUploadPic = false;
                  data.isCohabit = false;
                } else {
                  data.isUploadPic = data.isUploadPic == 1 ? true : false;
                  data.isCohabit = data.isCohabit == 1 ? true : false;
                }
                if (_this.$route.params.type == 'show') {
                  data.idCardNo = data.idCardNo ?
                    data.idCardNo.substring(0, 2) +
                    "******" +
                    data.idCardNo.substring(
                      data.idCardNo.length - 2,
                      data.idCardNo.length
                    ) :
                    "";
                }
                data.idcardimg = [];
                for (var key in data) {
                  if (/idCardPic\w/.test(key)) {
                    data.idcardimg.push({
                      name: `${key}.jpg`,
                      src: data[key]
                    });
                  }
                }
                if (data.orgBriefInfo != null) {
                  if(data.orgBriefInfo.orgType != 2) {
                      data.idcardimg.push({
                        name: `3.jpg`,
                        src: data.licenseUrl
                      });
                  }
                }
                if (data.orgBriefInfo != null) {
                  if (data.orgBriefInfo.isAuthorizer != 0) {
                    data.idcardimg.push({
                      name: `4.jpg`,
                      src: data.authorizeUrl
                    });
                  }
                }
                
                data.subAccount = [];
                data.subAccountList.forEach(item => {
                  data.subAccount.push({
                    roleNames: item.roleNames.join(","),
                    realname: item.realname,
                    phonenum: item.phonenum,
                    email: item.email,
                    username: item.username,
                    roleNames: item.roleNames,
                    createdDate: item.createdDate
                  });
                });
                if(data.orgBriefInfo != null) {
                  data.orgCode = data.orgBriefInfo.orgCode;
                  data.orgName = data.orgBriefInfo.orgName;
                  data.orgType = data.orgBriefInfo.orgType;
                  data.houseCount = data.orgBriefInfo.houseCount || 0;
                  data.entName = data.orgBriefInfo.entName;
                  data.socialCreditCode = data.orgBriefInfo.socialCreditCode;
                  data.isAuthorizer = data.orgBriefInfo.isAuthorizer == null ? 0 : data.orgBriefInfo.isAuthorizer;
                  data.category = data.orgBriefInfo.category == 7 ? true : false;
                }
                opt.cb(data);
              });
          }
        }
      };
    },
    activated() {
      let _this = this;
      this.$refs.detail.title = ["添加商户", "审核商户", "商户详情"][{
        add: 0,
        edit: 1,
        show: 2
      } [this.$route.params.type]];
      this.getApartmentList(this.$route.params.id);
    },
    created: function () {
      if (this.$route.query && this.$route.query.disabled == "true") {
        this.opt.form.forEach(item => {
          if (
            !(
              item.key == "authState" ||
              item.key == "allowOpenState" ||
              item.key == "rowRoomState" ||
              item.key == "isOpen" ||
              item.key == "category" ||
              item.key == "isPlan" ||
              item.key == "isCohabit" ||
              item.key == "isUploadPic" ||
              item.key == "isKey" ||
              item.key == "cohabitApartments" ||
              item.key == "uploadPicApartments" ||
              item.key == "apartments" ||
              item.key == "isPlanAutoRenew" ||
              item.key == "isPlanUnbind" ||
              item.key == "allowTempAuth"
            )
          ) {
            item["disabled"] = true;
          }
        });
      }
      console.log("user-detail created!!");
    },
    methods: {
      //获取房源列表
      getApartmentList(landlordId) {
        var dto = {
          pageNumber: "1",
          pageSize: "99999",
          landlordId: landlordId
        };
        return new Promise((resolve, reject) => {
          this.post("landlord-service/apartment/queryApartmentOfLandlord", dto).then(
            res => {
              res.unshift({
                label: "全部",
                value: "0",
                name: "全部",
                id: "0"
              });
              this.opt.form.forEach(data => {
                this.AllList = [];
                res.forEach(item => {
                  if (item.id) {
                    this.AllList.push(item.id);
                  }
                });
                if (data.key == "apartments") {
                  res.forEach(item => {
                    item.label = item.name;
                    item.value = item.id;
                  });
                  //  res.unshift({
                  //   label: "全部",
                  //   value: "0",
                  // });
                  data.opt.list = res;
                } else if (data.key == "cohabitApartments") {
                  res.forEach(item => {
                    item.label = item.name;
                    item.value = item.id;
                  });
                  data.opt.list = res;
                } else if (data.key == "uploadPicApartments") {
                  res.forEach(item => {
                    item.label = item.name;
                    item.value = item.id;
                  });
                  data.opt.list = res;
                }
              });
              resolve(res);
            }
          );
        });
      },
      // 属于学校商户类型时 办理排房功能不可关闭
      categoryChange(e,cb,form) {
          this.opt.form.forEach(item => {
            if (item.key == "isPlan") {
              item.disabled = e; 
            }
          })
      },
      // 子账号列表
    getSubAccountList() {
      let dto = {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          orgId: this.$route.params.orgId,
      };
      this.post('/user-service/subAccount/v1/page/query',dto,{
        isUseResponse: true,
      }).then(res => {
        if(res.data.code == 0) {
          this.subAccountList = res.data.data;
          this.subAccountTotal = res.data.total;
        }else {
          this.$message.error(res.data.msg);
        }
      })
    },
     handleSizeChange(val) {
        this.pageSize = val || 10;
        this.getSubAccountList();
      },
      handleCurrentChange(val) {
        this.currentPage = val || 1;
        this.getSubAccountList();
      },
    }
  };
</script>
<style lang="scss">
  .landlord-user-detail {
    .el-form-item__label {
      flex: none !important;
      width: 220px;
    }
  }

  @media only screen and (max-width: 500px) {
    .landlord-user-detail .el-form-item__label {
      width: auto;
      min-width: 100px;
    }
  }
</style>